import React from 'react';

const Image = ({ src, alt, height }) => {
  return (
    <div 
      className="image-container"
      style={{
        height: `${height}px`,
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '8px',
        backgroundColor: 'var(--light-gray)' // Placeholder while loading
      }}
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center 30%', // Adjust this value to control vertical positioning
          display: 'block',
          borderRadius: '8px'
        }}
      />
    </div>
  );
};

export default Image;